import React from "react";

const NavbarSocialsHome = (props) => {
  return (
    <div className={props.linksContainer}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://github.com/DomVournias"
        className="w-6 group"
      >
        <svg viewBox="0 0 128 128">
          <g fill="">
            <path
              fill="currentColor"
              className={props.links}
              d="M64 5.103c-33.347 0-60.388 27.035-60.388 60.388 0 26.682 17.303 49.317 41.297 57.303 3.017.56 4.125-1.31 4.125-2.905 0-1.44-.056-6.197-.082-11.243-16.8 3.653-20.345-7.125-20.345-7.125-2.747-6.98-6.705-8.836-6.705-8.836-5.48-3.748.413-3.67.413-3.67 6.063.425 9.257 6.223 9.257 6.223 5.386 9.23 14.127 6.562 17.573 5.02.542-3.903 2.107-6.568 3.834-8.076-13.413-1.525-27.514-6.704-27.514-29.843 0-6.593 2.36-11.98 6.223-16.21-.628-1.52-2.695-7.662.584-15.98 0 0 5.07-1.623 16.61 6.19C53.7 35 58.867 34.327 64 34.304c5.13.023 10.3.694 15.127 2.033 11.526-7.813 16.59-6.19 16.59-6.19 3.287 8.317 1.22 14.46.593 15.98 3.872 4.23 6.215 9.617 6.215 16.21 0 23.194-14.127 28.3-27.574 29.796 2.167 1.874 4.097 5.55 4.097 11.183 0 8.08-.07 14.583-.07 16.572 0 1.607 1.088 3.49 4.148 2.897 23.98-7.994 41.263-30.622 41.263-57.294C124.388 32.14 97.35 5.104 64 5.104z"
            ></path>
          </g>
        </svg>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/Dom_Vournias"
        className="w-6 group"
      >
        <svg viewBox="0 0 1024 1024">
          <path
            className={props.links}
            d="M928 254.3c-30.6 13.2-63.9 22.7-98.2 26.4a170.1 170.1 0 0 0 75-94a336.64 336.64 0 0 1-108.2 41.2A170.1 170.1 0 0 0 672 174c-94.5 0-170.5 76.6-170.5 170.6c0 13.2 1.6 26.4 4.2 39.1c-141.5-7.4-267.7-75-351.6-178.5a169.32 169.32 0 0 0-23.2 86.1c0 59.2 30.1 111.4 76 142.1a172 172 0 0 1-77.1-21.7v2.1c0 82.9 58.6 151.6 136.7 167.4a180.6 180.6 0 0 1-44.9 5.8c-11.1 0-21.6-1.1-32.2-2.6C211 652 273.9 701.1 348.8 702.7c-58.6 45.9-132 72.9-211.7 72.9c-14.3 0-27.5-.5-41.2-2.1C171.5 822 261.2 850 357.8 850C671.4 850 843 590.2 843 364.7c0-7.4 0-14.8-.5-22.2c33.2-24.3 62.3-54.4 85.5-88.2z"
            fill="currentColor"
          />
        </svg>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://gr.linkedin.com/in/domvournias"
        className="w-6 group"
      >
        <svg viewBox="0 0 128 128">
          <path
            fill="currentColor"
            className={props.links}
            d="M116 3H12a8.91 8.91 0 00-9 8.8v104.42a8.91 8.91 0 009 8.78h104a8.93 8.93 0 009-8.81V11.77A8.93 8.93 0 00116 3z"
          ></path>
          <path
            className={props.background}
            fill="currentColor"
            d="M21.06 48.73h18.11V107H21.06zm9.06-29a10.5 10.5 0 11-10.5 10.49 10.5 10.5 0 0110.5-10.49M50.53 48.73h17.36v8h.24c2.42-4.58 8.32-9.41 17.13-9.41C103.6 47.28 107 59.35 107 75v32H88.89V78.65c0-6.75-.12-15.44-9.41-15.44s-10.87 7.36-10.87 15V107H50.53z"
          ></path>
        </svg>
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.upwork.com/freelancers/~01f64995dbf453a3c6?s=1044578476142100494"
        className="w-6 group"
      >
        <svg viewBox="0 0 32 32">
          <path
            className={props.links}
            d="M24.75 17.542c-1.469 0-2.849-.62-4.099-1.635l.302-1.432l.01-.057c.276-1.521 1.13-4.078 3.786-4.078a3.605 3.605 0 0 1 3.604 3.604a3.605 3.605 0 0 1-3.604 3.599zm0-10.849c-3.385 0-6.016 2.198-7.083 5.818c-1.625-2.443-2.865-5.38-3.583-7.854h-3.646v9.484a3.403 3.403 0 0 1-3.396 3.396a3.406 3.406 0 0 1-3.396-3.396V4.657H0v9.484c0 3.885 3.161 7.068 7.042 7.068c3.885 0 7.042-3.182 7.042-7.068v-1.589c.708 1.474 1.578 2.974 2.635 4.297l-2.234 10.495h3.729l1.62-7.615c1.417.906 3.047 1.479 4.917 1.479c4 0 7.25-3.271 7.25-7.266c0-4-3.25-7.25-7.25-7.25z"
            fill="currentColor"
          />
        </svg>
      </a>
    </div>
  );
};

export default NavbarSocialsHome;
