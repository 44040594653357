import Code from "../../../../src/components/CodeBlocks/Code";
import { Break, ExLink } from "../../../../src/components/CodeBlocks/PostTypography";
import PostImage from "../../../../src/components/SinglePostAssets/PostImage";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import * as React from 'react';
export default {
  Code,
  Break,
  ExLink,
  PostImage,
  getImage,
  GatsbyImage,
  React
};